<template>
  <div
    v-if="heroSlides.length > 0"
    class="hero-slider"
    :class="{ 'hero-slider--single': heroSlides.length === 1 }"
    data-test="hero-slider"
  >
    <Swiper
      :modules="swiperModules.modules"
      :navigation="swiperModules.navigation"
      :pagination="swiperModules.pagination"
      :loop="swiperModules.loop"
      :allow-touch-move="swiperModules.allowTouchMove"
      class="hero-slider__swiper"
      :dir="i18n.localeProperties.value.dir"
    >
      <SwiperSlide v-for="(slide, index) in heroSlides" :key="index" class="hero-slide">
        <HeroSlideRegular v-if="slide.type === 'regular'" :slide="slide" />
        <HeroSlideSpecial v-else-if="slide.type === 'special'" :slide="slide" />
      </SwiperSlide>
      <NuxtIcon name="circle-prev" class="hero-slider__prev swiper-pagination-prev" />
      <div class="hero-slider__pagination" />
      <NuxtIcon name="circle-next" class="hero-slider__next swiper-pagination-next" />
    </Swiper>
    <div class="curve hero-slider__curve">
      <CurveSvg />
    </div>

    <template v-if="currentTheme !== EUROPE">
      <NuxtImg
        v-for="position in ['left', 'right']"
        :key="position"
        :class="`hero-slider__overlay-image hero-slider__overlay-image--${position}`"
        src="/products/fries.webp"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { gsap } from 'gsap';
import { useRuntimeConfig } from 'nuxt/app';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { type PropType, onMounted, toRefs } from 'vue';
import { useI18n } from '#i18n';
import HeroSlideRegular from '@/components/homepage/HeroSlideRegular.vue';
import HeroSlideSpecial from '@/components/homepage/HeroSlideSpecial.vue';
import { EUROPE, MIDDLE_EAST } from '@/constants/Themes';
import CurveSvg from '@/assets/patterns/curve.svg?component';
import { type HomepageHeroSlide } from '@/types/Sulu';

const props = defineProps({
  heroSlides: {
    type: Array as PropType<HomepageHeroSlide[]>,
    default: () => [],
  },
});

const config = useRuntimeConfig();
const i18n = useI18n();
const currentTheme = config.public.theme;

const { heroSlides } = toRefs(props);

const swiperModules = {
  modules: [Navigation, Pagination],
  pagination: {
    el: '.hero-slider__pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.hero-slider__next',
    prevEl: '.hero-slider__prev',
  },
  loop: heroSlides.value.length > 1,
  allowTouchMove: heroSlides.value.length > 1,
};

const animate = () => {
  if (currentTheme !== MIDDLE_EAST) {
    return;
  }

  gsap.to('.hero-slider__overlay-image--left', {
    xPercent: 0,
    opacity: 1,
    delay: 1,
  });

  gsap.to('.hero-slider__overlay-image--right', {
    x: 0,
    opacity: 1,
    delay: 1,
  });
};

onMounted(() => {
  animate();
});
</script>

<style lang="scss" scoped>
.hero-slider {
  background-color: $primary-color-800;
  position: relative;

  @include respond-min($tablet) {
    overflow: hidden;
    inline-size: 100%;
  }

  &__swiper {
    z-index: auto;
    transition: opacity 0.3s;

    &:not(:deep(.swiper-container-initialized)) {
      opacity: 0;
    }
  }

  :deep(.swiper-wrapper) {
    block-size: 870px;
  }

  &__pagination {
    @include exception-left;

    text-align: center;
    z-index: map-get($z-index, 'homepage-hero-pagination');
    position: absolute;
    padding: 30px 0;
    transform: translate(-50%, -30px);
    max-inline-size: 180px;

    @include respond-min($desktop) {
      padding: 0;
      transform: translate(-50%, -10px);
    }
  }

  &__prev,
  &__next {
    position: absolute;
    z-index: map-get($z-index, 'homepage-hero-pagination');
    inset-block-end: 43px;

    :deep(*) {
      // @workaround for NuxtIcon fill with currentColor
      fill: transparent !important;
    }

    @include respond-min($tablet) {
      inset-block-end: 35px;
    }

    @include respond-min($desktop) {
      inset-block-end: 0;
    }

    @include respond-min($desktop-xl) {
      margin: 0 15%;
    }
  }

  &__prev {
    inset-inline-start: 16px;

    @include respond-min($desktop-xl) {
      inset-inline-start: 0;
    }
  }

  &__next {
    inset-inline-end: 16px;

    @include respond-min($desktop-xl) {
      inset-inline-end: 0;
    }
  }

  &__curve {
    position: absolute;
    inset-block-end: -1px;
    overflow: hidden;
    block-size: 55px;
    inline-size: 100%;
    z-index: map-get($z-index, 'positive');

    @include respond-min($tablet) {
      block-size: $ellipse-radius-y-desktop;
    }

    @include respond-min($desktop) {
      inset-block-end: 0;
    }

    > svg {
      inline-size: 100%;
      block-size: 100%;
    }

    :deep(path) {
      fill: $primary-color-800;

      .layout--europe & {
        fill: $primary-color-600;
      }
    }
  }

  &__overlay-image {
    @include exception-left(-100px);

    position: absolute;
    inset-block-end: 50px;
    z-index: map-get($z-index, 'positive');
    inline-size: 250px;
    pointer-events: none;
    opacity: 0; // Initial GSAP opacity
    transform: translateX(-50%); // Initial GSAP position

    @include respond-max($tablet) {
      display: none;
    }

    @include respond-min($desktop) {
      inset-block-end: 0;
    }

    @include respond-min($desktop-xl) {
      inline-size: 350px;
    }

    &--right {
      @include exception-left(auto);
      @include exception-right(-100px);

      transform: translateX(50%) rotate(90deg); // Initial GSAP position
    }
  }
}

.hero-slider--single {
  .hero-slider__prev,
  .hero-slider__next,
  .hero-slider__pagination {
    display: none;
  }

  .hero-slider__curve {
    inset-block-end: 0;
  }
}

.layout--europe {
  .hero-slider {
    &__swiper {
      background-color: $primary-color-600;
    }
  }
}
</style>

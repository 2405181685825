<template>
  <section class="inspiration">
    <div v-if="config.public.theme === EUROPE" class="inspiration__curve-top curve-up">
      <CurveUp />
    </div>

    <div class="inspiration-collage-container">
      <TheBackground disable-concrete show-waves horizontal-waves />
      <div class="inspiration-collage layout__row" data-test="inspiration-collage">
        <LottieAnimation
          v-if="config.public.theme === MIDDLE_EAST && i18n.locale.value === EN"
          ref="titleWithCrunchyIEl"
          class="inspiration-collage__heading-title heading heading--h1"
          :title="headingTitle"
        />
        <h2 v-else class="inspiration-collage__heading-title heading heading--h1">
          {{ headingTitle }}
        </h2>

        <div class="inspiration-collage__grid">
          <div class="inspiration-collage__center-text">
            <h2 class="inspiration-collage__center-subtitle heading heading--h5">
              {{ centerTitle }}
            </h2>
            <span class="inspiration-collage__center-title heading heading--h1">
              {{ centerSubtitle }}
            </span>
          </div>

          <ArticleCard
            v-for="(inspiration, index) in inspirations"
            :key="inspiration.uuid"
            :article="inspiration"
            :class="`inspiration-collage__card inspiration-collage__card--${index + 1}`"
            data-test="inspiration-collage__card"
          />
        </div>

        <BaseLink :url="{ name: 'inspiration' }" class="inspiration-collage__cta underlined-cta">
          {{ $t('general.link.inspiration_overview') }}
        </BaseLink>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { gsap } from 'gsap';
import { useRuntimeConfig } from 'nuxt/app';
import {
  type PropType, type Ref, onMounted, ref,
} from 'vue';
import { useI18n } from '#i18n';
import CurveUp from '@/assets/patterns/curve-up.svg?component';
import ArticleCard from '@/components/article/ArticleCard.vue';
import TheBackground from '@/components/layout/TheBackground.vue';
import LottieAnimation from '@/components/LottieAnimation.vue';
import { EUROPE, MIDDLE_EAST } from '@/constants/Themes';
import { EN } from '@/constants/locales';
import { type ArticleExcerpt } from '@/types/Article';

const config = useRuntimeConfig();
const i18n = useI18n();
const titleWithCrunchyIEl: Ref = ref(null);

defineProps({
  inspirations: {
    type: Array as PropType<ArticleExcerpt[]>,
    default: () => [],
  },
  headingTitle: {
    type: String,
    required: true,
  },
  centerTitle: {
    type: String,
    required: true,
  },
  centerSubtitle: {
    type: String,
    required: true,
  },
});

onMounted(() => {
  gsap.to('.inspiration-collage__heading-title', {
    scrollTrigger: {
      trigger: '.inspiration-collage__grid',
      start: 'top bottom',
      invalidateOnRefresh: true,
    },
    opacity: 1,
    onComplete: () => {
      if (config.public.theme === MIDDLE_EAST) {
        if (titleWithCrunchyIEl.value === null) {
          return;
        }
        titleWithCrunchyIEl.value.play();
      }
    },
  });

  gsap.to('.inspiration-collage__card', {
    scrollTrigger: {
      trigger: '.inspiration-collage__grid',
      start: 'top bottom',
      invalidateOnRefresh: true,
    },
    opacity: 1,
    y: 0,
    duration: 1,
  });
});
</script>

<style lang="scss" scoped>
.inspiration-collage-container {
  position: relative;
  padding: 96px 0;
  overflow: hidden;

  @include respond-min($tablet) {
    padding: 200px 0;
  }
}

.inspiration-collage {
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &__heading-title {
    margin: 0 0 70px;
    opacity: 0;
    display: flex;
    justify-content: center;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-gap: 28px;
    min-block-size: 900px;

    @include respond-min($tablet) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }
  }

  &__center-subtitle,
  &__center-title {
    margin: 0;
  }

  &__center-text {
    text-align: center;
    align-self: center;
    order: 4;

    @include respond-min($tablet) {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
  }

  &__card {
    opacity: 0;
    transform: translateY(50px);

    @include respond-max($tablet) {
      block-size: 370px;
    }

    &--1 {
      order: 1;
    }

    &--2 {
      order: 2;
    }

    &--3 {
      order: 3;
    }

    &--4 {
      order: 5;
    }

    &--5 {
      order: 6;
    }

    &--6 {
      order: 7;
    }

    @include respond-min($tablet) {
      &--1 {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
      }

      &--2 {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }

      &--3 {
        grid-column: 3 / 4;
        grid-row: 1 / 2;
      }

      &--4 {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
      }

      &--5 {
        grid-column: 2 / 3;
        grid-row: 3 / 4;
      }

      &--6 {
        grid-column: 3 / 4;
        grid-row: 2 / 4;
      }
    }
  }

  &__cta {
    align-self: flex-end;
    margin-block-start: 40px;
  }
}

.layout--europe {
  .inspiration-collage-container {
    padding: 20px 0 100px;
    background: linear-gradient(0deg, rgba($primary-color-900, 0%) 60%, rgba($primary-color-900, 1) 100%);

    @include respond-min($desktop) {
      padding: 0 0 200px;
    }
  }

  .inspiration-collage {
    &__cta {
      margin: 60px auto 20px;
    }
  }
}
</style>

<template>
  <div class="hero-slide">
    <BaseImage class="hero-slide__image" :image="slide.background_image" />
    <div class="hero-slide__content layout__row">
      <span v-if="slide.subtitle" class="hero-slide__subtitle heading heading--h5">
        {{ slide.subtitle }}
      </span>
      <h1 v-if="slide.title" class="hero-slide__title heading">
        {{ slide.title }}
      </h1>
      <BaseLink
        v-if="slide.cta_url && slide.cta_label"
        class="hero-slide__button button"
        :class="{
          'button--primary': currentTheme === MIDDLE_EAST,
          'button--ghost': currentTheme === EUROPE,
        }"
        :url="slide.cta_url"
      >
        {{ slide.cta_label }}
      </BaseLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from 'nuxt/app';
import { type PropType } from 'vue';
import BaseImage from '@/components/base/BaseImage.vue';
import BaseLink from '@/components/base/BaseLink.vue';
import { EUROPE, MIDDLE_EAST } from '@/constants/Themes';
import { type HomepageHeroSlide } from '@/types/Sulu';

defineProps({
  slide: {
    type: Object as PropType<HomepageHeroSlide>,
    default: () => {},
  },
});

const config = useRuntimeConfig();
const currentTheme = config.public.theme;
</script>

<style lang="scss" scoped>
.hero-slide {
  position: relative;
  block-size: 100%;

  &::after {
    content: '';
    background: rgba($black, 0.4);
    position: absolute;
    inset: 0;

    .layout--europe & {
      background: linear-gradient(120.6deg, rgba($black, 0.507) 6.78%, rgba($black, 0.0325) 85.7%);
    }
  }

  &__image {
    position: absolute;
    inline-size: 100%;
    block-size: 100%;
    object-fit: cover;
  }

  &__content {
    position: relative;
    padding-block-start: 200px;
    z-index: map-get($z-index, 'positive');
  }

  &__subtitle,
  &__title {
    margin: 0;
    max-inline-size: 820px;
  }

  &__button {
    inline-size: fit-content;
    margin-block-start: 24px;
  }
}
</style>

<template>
  <section class="homepage-introduction" data-test="introduction-block">
    <div class="layout__row">
      <div class="homepage-introduction__column">
        <BaseImage v-if="image" class="homepage-introduction__column-image" :image="image" />
        <span class="homepage-introduction__title heading heading--h1">
          {{ title }}
        </span>
      </div>
      <div class="homepage-introduction__column">
        <div class="homepage-introduction__text" v-html="text" />
        <BaseLink v-if="buttonText && buttonUrl" :url="buttonUrl" class="homepage-introduction__button button">
          {{ buttonText }}
        </BaseLink>
      </div>
    </div>

    <div class="homepage-introduction__section-image-container">
      <NuxtImg
        class="homepage-introduction__section-image"
        src="/products/fries.webp"
        :alt="$t('general.fries')"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { gsap } from 'gsap';
import { onMounted, type PropType } from 'vue';
import BaseImage from '@/components/base/BaseImage.vue';
import BaseLink from '@/components/base/BaseLink.vue';
import useAnimationHelper from '@/composables/useAnimationHelper';
import useUIHelper from '@/composables/useUIHelper';
import { type SuluMedia } from '@/types/Sulu';

defineProps({
  title: {
    type: String as PropType<string>,
    required: true,
  },
  image: {
    type: Object as PropType<SuluMedia>,
    default: () => undefined,
  },
  text: {
    type: String as PropType<string>,
    required: true,
  },
  buttonText: {
    type: String as PropType<string>,
    default: '',
  },
  buttonUrl: {
    type: String as PropType<string>,
    default: '',
  },
});

const { getXValue } = useAnimationHelper();
const { isTabletDevice } = useUIHelper();

const animateSlideIn = (): void => {
  gsap.from('.homepage-introduction__section-image', {
    x: getXValue(500),
    scrollTrigger: {
      trigger: '.homepage-introduction',
      start: isTabletDevice.value ? 'top+=300 center' : 'top center',
    },
  });
};

onMounted(() => {
  animateSlideIn();
});
</script>

<style scoped lang="scss">
.homepage-introduction {
  position: relative;
  padding-block-start: 150px;
  background-color: $primary-color-800;
  z-index: map-get($z-index, 'positive');

  .layout--europe & {
    background-color: $primary-color-600;
  }

  @include respond-min($desktop) {
    padding-block-end: 120px;
  }

  @include respond-min($desktop) {
    .layout__row {
      display: flex;
    }
  }

  &__section-image-container {
    display: flex;
    justify-content: flex-end;
  }

  &__section-image {
    inline-size: 250px;
    margin-inline-end: -50px;

    @include respond-min($desktop) {
      position: absolute;
      inline-size: 250px;
      inset-block-end: -30%;
    }

    @include respond-min($desktop-xl) {
      inline-size: 400px;
      inset-block-end: -60%;
    }
  }

  &__column {
    display: flex;
    gap: 32px;
    position: relative;
    flex-direction: column;

    @include respond-min($desktop) {
      gap: 40px;
      justify-content: center;

      &:nth-child(1) {
        inline-size: 40%;
        align-items: center;
      }

      &:nth-child(2) {
        inline-size: 60%;
      }
    }
  }

  &__column-image {
    max-block-size: 330px;
    inline-size: fit-content;

    @include respond-max($desktop) {
      display: none;
    }
  }

  &__title {
    margin-block-end: 24px;

    @include respond-min($desktop) {
      position: absolute;
      text-align: center;
    }
  }

  &__button {
    padding: 16px 72px;
    text-align: center;

    @include respond-min($desktop) {
      inline-size: fit-content;
    }
  }
}
</style>

<template>
  <section class="homepage" data-cursor="lighting">
    <TheBackground />
    <template v-if="homepage && homepage.content">
      <HeroSlider :hero-slides="homepage.content.hero_slides" />
      <HomepageIntroduction
        :title="homepage.content.introduction_title"
        :image="homepage.content.introduction_image"
        :text="homepage.content.introduction_text"
        :button-text="homepage.content.introduction_button_text"
        :button-url="homepage.content.introduction_button_url"
      />

      <section v-if="homepage.content.show_products" class="product-range">
        <div v-if="currentTheme === EUROPE" class="product-range__curve-top curve-up">
          <CurveUp />
        </div>

        <ProductRange
          v-if="homepage.content.retail_products.items?.length > 0"
          :products="homepage.content.retail_products.items"
          :show-overview-button="false"
        />

        <div class="product-range__curve-bottom curve-up">
          <CurveUp />
        </div>
      </section>

      <section v-if="homepage.content.testimonials?.length" class="homepage__testimonials">
        <BlockTestimonials
          v-for="(block, index) in homepage.content.testimonials"
          :key="index"
          :block="block"
        />
      </section>

      <section v-if="homepage.content.about_blocks" class="homepage__about-blocks" data-test="about-blocks">
        <BlockAbout
          v-for="(block, index) in homepage.content.about_blocks"
          :key="index"
          :title="block.title"
          :subtitle="block.subtitle"
          :image="block.image"
          :text="block.text"
          :link="Array.isArray(block.link) ? undefined : block.link"
          :link-label="block.link_label"
          :skewed-card-background="config.public.theme === MIDDLE_EAST"
        />
        <div class="about-blocks__cta-container layout__row">
          <BaseLink :url="homepage.content.about_button_url" class="about__cta underlined-cta">
            {{ homepage.content.about_button_text }}
          </BaseLink>
        </div>
      </section>

      <InspirationCollage
        v-if="homepage.content.show_inspirations"
        :inspirations="homepage.content.retail_articles.items"
        :heading-title="homepage.content.inspirations_heading_title"
        :center-title="homepage.content.inspirations_center_title"
        :center-subtitle="homepage.content.inspirations_center_subtitle"
      />
    </template>
  </section>
</template>

<script setup lang="ts">
import { useAsyncData, useRuntimeConfig, useHead } from 'nuxt/app';
import { useI18n } from '#i18n';
import CurveUp from '@/assets/patterns/curve-up.svg?component';
import BlockAbout from '@/components/blocks/BlockAbout.vue';
import BlockTestimonials from '@/components/blocks/BlockTestimonials.vue';
import HeroSlider from '@/components/homepage/HeroSlider.vue';
import HomepageIntroduction from '@/components/homepage/HomepageIntroduction.vue';
import InspirationCollage from '@/components/inspiration/InspirationCollage.vue';
import TheBackground from '@/components/layout/TheBackground.vue';
import ProductRange from '@/components/product/ProductRange.vue';
import useCms from '@/composables/useCms';
import useSeo from '@/composables/useSeo';
import { EUROPE, MIDDLE_EAST } from '@/constants/Themes';

const i18n = useI18n();
const config = useRuntimeConfig();
const currentTheme = config.public.theme;

const { getHomepage } = useCms(i18n, config.public.apiUrl, currentTheme);
const { data: homepage } = await useAsyncData('homepage', getHomepage());

const seoFallback = {
  title: i18n.t('homepage.fallback_title'),
  image: homepage.value?.content.hero_slides[0]?.background_image,
};

const { head } = useSeo(config.public.apiUrl);
useHead(head(homepage.value?.extension, seoFallback));
</script>

<style scoped lang="scss">
.homepage {
  &__about-blocks {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding-block-end: 80px;

    @include respond-min($desktop) {
      gap: 80px;
      padding-block: 80px;
    }

    :deep(.about-block__content-container) {
      max-inline-size: 420px + 120px;

      @include respond-min($desktop) {
        padding-inline: 60px;
        inline-size: 100vw;
      }
    }

    &__cta-container {
      display: flex;
      justify-content: flex-end;

      @include respond-min($desktop) {
        justify-content: center;
      }
    }
  }
}

.product-range {
  &__curve-top,
  &__curve-bottom {
    > svg {
      fill: $primary-color-800;
    }
  }

  &__curve-top {
    background-color: $primary-color-600;
  }

  &__curve-bottom {
    transform: rotate(180deg);
  }
}

:deep(.inspiration__curve-top) {
  margin-block-start: -80px;
}
</style>
